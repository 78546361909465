// axiosConfig.js
import axios from 'axios';
import { SERVER_URL } from './config';

const instance = axios.create({
  baseURL: SERVER_URL,
});

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    // You can add headers or do other stuff here
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let errorMessage = 'An unexpected error occurred';
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      switch (error.response.status) {
        case 400:
          errorMessage = 'Bad request. Please check your input.';
          break;
        case 401:
          errorMessage = 'Unauthorized. Please log in.';
          break;
        case 403:
          errorMessage = 'Forbidden. You don\'t have permission to access this resource.';
          break;
        case 404:
          errorMessage = 'Resource not found.';
          break;
        case 500:
          errorMessage = 'Internal server error. Please try again later.';
          break;
        default:
          errorMessage = `Server responded with error: ${error.response.data.message || error.response.statusText}`;
      }
    } else if (error.request) {
      // The request was made but no response was received
      errorMessage = 'No response received from server. Please check your internet connection.';
    }
    return Promise.reject(new Error(errorMessage));
  }
);

export default instance;

