import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllLinks } from '../redux/notesSlice';
import { Link } from 'react-router-dom';
import LoadingBar from './LoadingBar';

const LinksList = () => {
    const dispatch = useDispatch();
    const links = useSelector(state => state.notes.allLinks);
    const status = useSelector(state => state.notes.linksStatus);
    const error = useSelector(state => state.notes.linksError);
    const { currentPage, totalPages, totalLinks } = useSelector(state => state.notes.linksPagination);

    const [limit] = useState(100);

    useEffect(() => {
        
        if (status === 'idle') {
            dispatch(fetchAllLinks({ page: 1, limit }));
        }
    }, [status, dispatch, limit]);

    const handlePageChange = (newPage) => {
        dispatch(fetchAllLinks({ page: newPage, limit }));
    };

    const formatNoteId = (id) => {
        const parts = id.split('-');
        if (parts.length < 2) {
            return id;
        }

        const dateTime = parts[0];
        const wordParts = parts.slice(1);

        let formattedDate = dateTime;
        if (dateTime.length >= 8) {
            formattedDate = `${dateTime.slice(0,4)}-${dateTime.slice(4,6)}-${dateTime.slice(6,8)}`;
        }

        const formattedWord = wordParts.join(' ');
        return `${formattedDate} (${formattedWord})`;
    };

const formatDate = (dateString) => {
  console.log('Formatting date:', dateString);
  if (!dateString) return 'N/A';
  const d = new Date(dateString);
  if (isNaN(d.getTime())) {
    console.error('Invalid date:', dateString);
    return 'Invalid Date';
  }
  // Check if the date is close to the Unix epoch
  if (d.getFullYear() < 1971) {
    console.error('Date too old, possibly invalid:', dateString);
    return 'Invalid Date';
  }
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};


    if (status === 'loading' && links.length === 0) {
        return <LoadingBar />;
    }

    if (status === 'failed') {
        return <div className="error-message">Error: {error}</div>;
    }

    return (
    <div className="links-list">
      <h1>All Links</h1>
      {links.length === 0 ? (
        <p>No links found in any notes.</p>
      ) : (
        <>
          <ol className="links-list-items">
            {links.map((link, index) => (
              <li key={index} className={`link-item ${link.count > 1 ? 'ap2' : ''}`}>
                <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-url">
                  {link.url}
                </a>
                <span className="link-count"> (Appears {link.count} {link.count === 1 ? 'time' : 'times'})</span>
                <span className="link-latest-date"> - Latest entry: {formatDate(link.latestDate)}</span>
                <span className="link-notes"> - Found in note(s): </span>
                {link.noteIds.map((noteId, noteIndex) => (
                  <React.Fragment key={noteId}>
                    {noteIndex > 0 && ', '}
                    <Link to={`/note/${encodeURIComponent(noteId)}`} className="note-link">
                      {formatNoteId(noteId)}
                    </Link>
                  </React.Fragment>
                ))}
              </li>
            ))}
          </ol>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={page === currentPage ? 'active' : ''}
                disabled={page === currentPage}
              >
                {page}
              </button>
            ))}
          </div>
          <p className="total-links">Total unique links: {totalLinks}</p>
        </>
      )}
      {status === 'loading' && links.length > 0 && <LoadingBar />}
    </div>
    );
};

export default LinksList;