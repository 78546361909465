// notesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axiosConfig';

export const fetchNotes = createAsyncThunk(
  'notes/fetchNotes',
  async ({ page = 1, tag = null, bookmarked = false, shouldAppend = false }, { rejectWithValue }) => {
    try {
      let url = `/api/notes?page=${page}`;
      if (tag) {
        url += `&tag=${encodeURIComponent(tag)}`;
      }
      if (bookmarked) {
        url += '&bookmarked=true';
      }
      const response = await axios.get(url);
      return { ...response.data, shouldAppend };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while fetching notes');
    }
  }
);

export const fetchNoteById = createAsyncThunk(
  'notes/fetchNoteById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/notes/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while fetching the note');
    }
  }
);

export const addNote = createAsyncThunk(
  'notes/addNote',
  async ({ content, autoTags, removedAutoTags }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/notes', { content, autoTags, removedAutoTags });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while adding the note');
    }
  }
);

export const updateNote = createAsyncThunk(
  'notes/updateNote',
  async ({ id, content, tags }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/notes/${id}`, { content, tags });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while updating the note');
    }
  }
);

export const deleteNote = createAsyncThunk(
  'notes/deleteNote',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/notes/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while deleting the note');
    }
  }
);

export const toggleNoteLock = createAsyncThunk(
  'notes/toggleNoteLock',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-lock`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while toggling the note lock');
    }
  }
);

export const toggleNoteBookmark = createAsyncThunk(
  'notes/toggleNoteBookmark',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-bookmark`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while toggling the note bookmark');
    }
  }
);

export const toggleNoteCollapse = createAsyncThunk(
  'notes/toggleNoteCollapse',
  async ({ id, isCollapsed }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/api/notes/${id}/toggle-collapse`, { isCollapsed });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while toggling the note collapse');
    }
  }
);

export const addTag = createAsyncThunk(
  'notes/addTag',
  async ({ noteId, tag }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/notes/${noteId}`, { tags: [tag] });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while adding the tag');
    }
  }
);

export const removeTag = createAsyncThunk(
  'notes/removeTag',
  async ({ noteId, tag }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/notes/${noteId}`, { tags: [tag], removeTag: true });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while removing the tag');
    }
  }
);

export const fetchAllLinks = createAsyncThunk(
  'notes/fetchAllLinks',
  async ({ page = 1, limit = 100 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/all-links?page=${page}&limit=${limit}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while fetching links');
    }
  }
);

const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    currentPage: 1,
    totalPages: 1,
    currentNote: null,
    currentNoteStatus: 'idle',
    currentNoteError: null,
    allLinks: [],
    linksStatus: 'idle',
    linksError: null,
    linksPagination: {
      currentPage: 1,
      totalPages: 1,
      totalLinks: 0
    }
  },
  reducers: {
    clearCurrentNote: (state) => {
      state.currentNote = null;
      state.currentNoteStatus = 'idle';
      state.currentNoteError = null;
    },
    updateNoteOptimistic: (state, action) => {
      const index = state.items.findIndex(note => note._id === action.payload._id);
      if (index !== -1) {
        state.items[index] = action.payload;
      }
    },
    deleteNoteOptimistic: (state, action) => {
      state.items = state.items.filter(note => note._id !== action.payload);
    },
    toggleNoteCollapseOptimistic: (state, action) => {
      const { id, isCollapsed } = action.payload;
      const note = state.items.find(note => note._id === id);
      if (note) {
        note.isCollapsed = isCollapsed;
      }
    },
    addTagOptimistic: (state, action) => {
      const { noteId, tag } = action.payload;
      const note = state.items.find(note => note._id === noteId);
      if (note && !note.tags.includes(tag)) {
        note.tags.push(tag);
      }
    },
    removeTagOptimistic: (state, action) => {
      const { noteId, tag } = action.payload;
      const note = state.items.find(note => note._id === noteId);
      if (note) {
        note.tags = note.tags.filter(t => t !== tag);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.shouldAppend) {
          state.items = [...state.items, ...action.payload.notes];
        } else {
          state.items = action.payload.notes;
        }
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchNoteById.pending, (state) => {
        state.currentNoteStatus = 'loading';
      })
      .addCase(fetchNoteById.fulfilled, (state, action) => {
        state.currentNoteStatus = 'succeeded';
        state.currentNote = action.payload;
        const index = state.items.findIndex(note => note._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(fetchNoteById.rejected, (state, action) => {
        state.currentNoteStatus = 'failed';
        state.currentNoteError = action.payload;
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.items.unshift(action.payload);
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const index = state.items.findIndex(note => note._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
        if (state.currentNote && state.currentNote._id === action.payload._id) {
          state.currentNote = action.payload;
        }
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.items = state.items.filter(note => note._id !== action.payload);
        if (state.currentNote && state.currentNote._id === action.payload) {
          state.currentNote = null;
        }
      })
      .addCase(toggleNoteLock.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.isLocked = action.payload.isLocked;
        }
        if (state.currentNote && state.currentNote._id === action.payload._id) {
          state.currentNote.isLocked = action.payload.isLocked;
        }
      })
      .addCase(toggleNoteBookmark.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.isBookmarked = action.payload.isBookmarked;
        }
        if (state.currentNote && state.currentNote._id === action.payload._id) {
          state.currentNote.isBookmarked = action.payload.isBookmarked;
        }
      })
      .addCase(toggleNoteCollapse.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.isCollapsed = action.payload.isCollapsed;
        }
        if (state.currentNote && state.currentNote._id === action.payload._id) {
          state.currentNote.isCollapsed = action.payload.isCollapsed;
        }
      })
      .addCase(addTag.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.tags = action.payload.tags;
        }
        if (state.currentNote && state.currentNote._id === action.payload._id) {
          state.currentNote.tags = action.payload.tags;
        }
      })
      .addCase(removeTag.fulfilled, (state, action) => {
        const note = state.items.find(note => note._id === action.payload._id);
        if (note) {
          note.tags = action.payload.tags;
        }
        if (state.currentNote && state.currentNote._id === action.payload._id) {
          state.currentNote.tags = action.payload.tags;
        }
      })
      .addCase(fetchAllLinks.pending, (state) => {
        state.linksStatus = 'loading';
      })
  .addCase(fetchAllLinks.fulfilled, (state, action) => {
    state.linksStatus = 'succeeded';
    state.allLinks = action.payload.links.map(link => ({
      ...link,
      count: link.count || 1,
      latestDate: link.latestDate ? new Date(link.latestDate).toISOString() : null
    }));
    state.linksPagination = {
      currentPage: action.payload.currentPage,
      totalPages: action.payload.totalPages,
      totalLinks: action.payload.totalLinks
    };
  })
      .addCase(fetchAllLinks.rejected, (state, action) => {
        state.linksStatus = 'failed';
        state.linksError = action.payload;
      });
  },
});

export const { 
  clearCurrentNote, 
  updateNoteOptimistic, 
  deleteNoteOptimistic,
  toggleNoteCollapseOptimistic,
  addTagOptimistic,
  removeTagOptimistic
} = notesSlice.actions;

export default notesSlice.reducer;