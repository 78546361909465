import React, { useState, useEffect, useRef } from 'react';
import axios from './axiosConfig';

const MAX_ATTEMPTS = 5; // Set the maximum number of attempts
const LOCKOUT_TIME = 1 * 60 * 1000; // 5 minutes in milliseconds

const PasscodeEntry = ({ onPasscodeVerified }) => {
  const [passcode, setPasscode] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [lockoutEndTime, setLockoutEndTime] = useState(null);
  const inputRefs = useRef([]);

  useEffect(() => {
    const storedSession = localStorage.getItem('notesAppSession');
    if (storedSession === 'true') {
      onPasscodeVerified();
    }
    
    // Check if the app is in a locked state
    const storedLockoutEnd = localStorage.getItem('notesAppLockoutEnd');
    if (storedLockoutEnd) {
      const lockoutEnd = parseInt(storedLockoutEnd, 10);
      if (lockoutEnd > Date.now()) {
        setIsLocked(true);
        setLockoutEndTime(lockoutEnd);
      } else {
        localStorage.removeItem('notesAppLockoutEnd');
      }
    }

    inputRefs.current[0].focus();
  }, [onPasscodeVerified]);

  useEffect(() => {
    let timer;
    if (isLocked) {
      timer = setInterval(() => {
        if (Date.now() >= lockoutEndTime) {
          setIsLocked(false);
          setAttempts(0);
          localStorage.removeItem('notesAppLockoutEnd');
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isLocked, lockoutEndTime]);

  const handleChange = (index, value) => {
    if (value.length <= 1) {
      const newPasscode = [...passcode];
      newPasscode[index] = value;
      setPasscode(newPasscode);

      if (value !== '' && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && passcode[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLocked) return;

    const fullPasscode = passcode.join('');
    if (fullPasscode.length !== 6) {
      setError('Please enter passcode.');
      return;
    }

    try {
      const response = await axios.post('/api/verify-passcode', { passcode: fullPasscode });
      if (response.data.isValid) {
        localStorage.setItem('notesAppSession', 'true');
        onPasscodeVerified();
      } else {
        handleFailedAttempt();
      }
    } catch (error) {
      handleFailedAttempt();
    }
  };

  const handleFailedAttempt = () => {
    const newAttempts = attempts + 1;
    setAttempts(newAttempts);
    setError(`Invalid passcode. ${MAX_ATTEMPTS - newAttempts} attempts remaining.`);
    setPasscode(['', '', '', '', '', '']);
    inputRefs.current[0].focus();

    if (newAttempts >= MAX_ATTEMPTS) {
      const lockoutEnd = Date.now() + LOCKOUT_TIME;
      setIsLocked(true);
      setLockoutEndTime(lockoutEnd);
      localStorage.setItem('notesAppLockoutEnd', lockoutEnd.toString());
      setError(`Too many failed attempts.`);
    }
  };

  const renderLockoutMessage = () => {
    
    return `Account locked. Who are you?`;
  };

  return (
    <div className="passcode-entry">
      <h2>Enter Passcode</h2>
      <form onSubmit={handleSubmit}>
        <div className="passcode-inputs">
          {passcode.map((digit, index) => (
            <input
              key={index}
              ref={el => inputRefs.current[index] = el}
              type="password"
              maxLength="1"
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              required
              disabled={isLocked}
            />
          ))}
        </div>
        <button type="submit" disabled={isLocked}>Submit</button>
      </form>
      {error && <p className="error">{error}</p>}
      {isLocked && <p className="error">{renderLockoutMessage()}</p>}
    </div>
  );
};

export default PasscodeEntry;